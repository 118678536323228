/**
COLOR SCHEME - START
 */

/**
 Prefix: --u-
 Color: [primary,black,info=blue,success=green,pink]
 optional(Suffix): [-dark,-shadow,-alt]
 */
:root {
  --primary: #efca78 !important;

  --u-primary: #efca78;
  --u-primary-dark: #e49455 ;
  --u-primary-vibrant:#db4914;
  --u-primary-light:#f8a74b;
  --u-primary-ligther:#fabd78;

  --u-red:#cc0d1b;
  --u-pink:#ff4a57;


  --u-black: #494949 ;
  --u-black-light:#666666;
  --u-black-lighter:#777777;
  --u-black-dark: #232323 ;
  --u-black-shadow: #43434371 ;

  --u-bg : #e0e0e0;

  --u-gradient: linear-gradient(-90deg, var(--u-primary-ligther),var(--u-primary-dark));
}
.i { font-style:  italic; }

.margin-10 { margin: 10px !important; }
.margin-15 { margin: 15px !important; }
.margin-20 { margin: 20px !important; }
.margin-15 { margin: 25px !important; }
