.alertsViewFlex {
  display: flex;

  flex-flow: wrap;
}

.alertsViewFlex .MuiPaper-root {
  margin-left: 15px;
  margin-top: 15px;
  background: var(--u-black);

}

.alertsViewFlex .MuiPaper-root .MuiTypography-root {
  color: white;
}

.alertsView {

  animation: slideInFromLeft .3s;
  animation-fill-mode: forwards;
}

.alertsView .card {
  user-select: none;
  margin: 10px;
}

.alertsView .card.enabledAlarmType:hover {
  background: #eee;
  cursor: pointer;
  border-color: var(--primary);
}

.disabledAlarmType {
  opacity: 0.5;
  cursor: not-allowed;
}

@keyframes slideInFromLeft {
  0% { padding-left: 0px; padding-right: 20px; }
  100% { padding-left: 20px; padding-right: 0px;}
}

.buttonlike.active, .alertsView .card.enabledAlarmType:active {
  box-shadow: 0 0 0 0.2rem var(--u-primary-shadow) !important;
}

.floatingUpperButtons {

  width: calc(100% - 20px);
  display: inherit;
  top: 80px;
  left: 10px;
}

.stickyRibbon {
  background: white;
  position: sticky;
  top: -25px;
  z-index: 99;
  margin: -30px -30px 0 -30px;
  padding: 15px 30px 15px 30px;
  border-bottom: 1px solid #ccc;
  border-radius: 8px 8px 0 0;
}

.stickyRibbon h5 {
  font-weight: normal !important;
}

.taskContainer form {
  padding: 15px;
}

.moveLock {
  position: absolute;
  width: 100%;
  background: #ffffffb3;
  height: 100%;
  z-index: 99;
}

.moveLock span {
  opacity: 0;
}

.moveLock span {
  padding: 20px;
  margin: 20px;
  display: block;
  background: white;
  width: calc(100% - 72px);
  border-radius: 4px;
  border: 1px solid #aaa;
  opacity: 1;
}

#form_prefix .field-description {
  color: #6c757d !important;
}

.control-label {
  font-size: 20px;
}

#alert-container .col-sm-8 {
  max-width: 775px;
}

#form_prefix .checkbox label, .wrappedCheckbox {
  padding: 5px 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
}

.wrappedCheckbox input {
  margin-right: 5px !important;
}

#form_prefix .checkbox label span {
  margin-left: 5px;
}

/**top alert should be sticky*/
.taskContainer .alert {
  position: sticky;
  top: 5px;
}

/** stream configurator style */
body.scuiShown {
  overflow: hidden;
}
body.scuiShown #scuiIframe {
  opacity:0;
  animation: scui-show .3s;
  animation-delay: .1s;
  animation-fill-mode: forwards;
}
@keyframes scui-show {
  0% { opacity : 0; }
  100% { opacity: 1; }
}
#scuiIframe {
  /* display:none; will make SCUI crash on firefox, so moving the IFrame to off-canvas*/
  z-index: -1231231;
  position: absolute;
  top: 0; left: 0;
  height: 1px; width: 1px;
  opacity: 0;
}

.scuiShown #scuiIframe {

  display: block;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  border: none;
  opacity: 1;

}

.setCamera svg {
  vertical-align: sub;
}

. .card .dropdown-menu.show {
  width: 100%;
}

. .card .marginLeftCheckbox {
  margin-left: 6px;
}

.cameraFormName {
  font-size: 20px;
}

. button.btn .spinner-border-sm {
  width: 1.2rem;
  height: 1.2rem;
  border-width: .3em
}

.error-detail.bs-callout.bs-callout-info {
  display: none;
}


.montserrat-bold {
  font-weight: bold;
  font-family: 'Montserrat';
}

.padding-15 {
  padding: 15px;
}

.roiImage {
  display: inline-block;
  border-radius: 4px;
  width: 360px;
  vertical-align: -2px;
}

.roiEditButtons {
  display: inline-block;
  opacity: 1;
  transition: opacity .25s;
  border-radius: 4px;
  vertical-align: 10px;
  padding: 10px;
}
.roiEditButtons-counter {
  vertical-align: 32px;
}

.roiEditButtons:empty {
  opacity: 0;
  transition: opacity 0s;
}

@media screen and (min-width: 1440px) {
  .roiEditButtons {
    margin-left: 10px;
  }

}
@media screen and (max-width: 1100px) {
  .resourceQuota {
    width: 1.5px !important;
  }
}

.taskContainer h5, .taskHeader .control-label {
  padding: 10px 0 5px 0;
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: bold;
  display: block;
}

.taskContainer h6 {
  padding: 10px 0 5px 0;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.objectFieldHolder {
  display: block;
  margin: 0 -10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #f1f1f1;
}

.setCamera.card {
  padding-top: 5px;
}

.float-right {
  float: right;
}

.float-rightButton {
  float: right;
  margin: 0 -10px;
}

.circularProgress-inButton {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.stickyRibbon .MuiTypography-h5.h1 {
  padding: 0px;
}

.coverTasks::before {
  content: ' ';
  position: absolute;
  margin-top: 0;
  width: calc(100% - 40px);
  height: calc(100% - 35px);
  z-index: 99;
  background: white;
  opacity: .65;
  cursor: not-allowed;
}

.setCamera.covered {
  background: white;
  opacity: .65;
  cursor: not-allowed;
  pointer-events: none;
}

.white-a {
  color: #eee !important;
  cursor: pointer;
  text-decoration: underline;
}

.roiEditHolder {
  background: #eee;
}

.alarmFormNote {
  margin-top: 3px;
  margin-bottom: 1em;
  font-size: small;
  color: rgba(0, 0, 0, 0.65)
}

#secondaryTitle {
  color: #bbb;
  margin-left: 15px;
}

.objectFieldHolder .MuiFormControl-root {
  margin-left: 5px;
  margin-right: 5px;
}

.objectFieldHolder .MuiFormControl-root.MuiFormControl-fullWidth {
  width: calc(100% - 10px);
}

.flexSelector .Mui-checked + .MuiTypography-root svg {
  color: #333;
}

.flexSelector span + .MuiTypography-root svg {
  color: #888;
}

.detectionTypeSelector {
  padding: 10px;
  padding-top: 15px;
}

.detTypeSel-icon {
  display: inline-block;
  width: 30px;
  text-align: center;
}

.taskContainer .MuiAccordion-root.MuiPaper-elevation1 {
  box-shadow: none;
  border: 1px solid silver;
}

.MuiAccordionSummary-root.Mui-expanded {
  border-bottom: 1px solid silver;
}

.h5 {
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: bold;
}

.MuiAccordionSummary-root .cloneDelete {
  float: right;
  display: block;
  position: absolute;
  right: 45px;
  top: 6px;
}

.MuiAccordionSummary-root.Mui-expanded .cloneDelete {
  top: 14px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container > div {
  width: 30%;
  margin-left: 10px;
  text-align: left;
}

.roiEditButtons.counter h5 {
  padding-top: 5px;
  padding-bottom: 5px
}

.enumSelector-root p.MuiFormHelperText-root {
  margin: 5px 10px;
}

.MuiAccordionSummary-content svg {
  margin: 0 5px;
}

.cta.main .MuiButton-label {
  color: white;
}

.MuiAccordionSummary-root {
  padding: 3px 16px;
  border-radius: var(--bradius);
}

.taskContainer form h5 {
  margin-top: 10px;
  padding-bottom: 3px;
}

.cameraListContainer {
  margin-top: 5px;
}


.alarmTypeCard {
  width: 32rem;
  height: 220px;
  position: relative;
  cursor: pointer;
}

.enabledAlarmType:hover {
  outline: 4px auto var(--u-primary);
  border-color: var(--u-primary);
  transition: outline .2s, background-color .2s;
  background-color: #333;
}

.disabledAlarmType > button > div {
  cursor: not-allowed !important;
}

.card-img-top {
  width: 130px;
  height: 125px;
}

.alarmTypeCard p {
  display: block;
  margin-top: -125px;
  margin-left: 145px;
  width: 330px;

}

.alertsViewFlex {
  margin: 0 -15px;
}

.taskContainer .MuiAccordion-root.MuiPaper-elevation1.hasError {
  border: 1px solid red;
}

.resourceQuota {
  display: inline-block;
  width: 2px;
  height: 18px;
  transition: all .6s;
}
.resourceQuotaDisplay, .resourceQuota.display.used {
  border-radius: 5px;
  opacity: 1;
  width: 60px;
  margin-right:5px;
  font-family: 'Montserrat';
  font-weight: bold;
  text-align: center;
  padding-top: 7px;
  height: 21px;
  vertical-align: 5px;
  display: inline-block;
}

.resourceQuotaOuter {
  display: inline-block;
}

.resourceQuota.used {
  background-color: var(--u-black);
}

.resourceQuota.tooMuch {
  background-color: var(--u-red);
  border-color: var(--u-red);
}

.resourceQuota.wannaBeEaten {
  background-color: var(--primary);
  border-color: var(--primary);
}

.resourceQuota.wannaBeEaten.display {
  background-color: var(--u-primary-vibrant);
  border-color: var(--u-primary-vibrant);
}

.resourceQuota.wannaBeFree {
  background-color: #999;
}

.resourceQuota.wannaBeFree.display {
  background-color: #999;
}

.resourceQuota.free {
  background-color: white;
}

.resourceQuotaHolder {
  display: inline-block;
  height: 18px;
  overflow: hidden;
  border-radius: 5px;
  border: 2px solid var(--u-black);
  transition:all .4s;
}

#resQuotaRoot {

  transition: all .2s;
  min-height: 40px;
  display: table;
  margin-top: -51px;
  margin-left: 250px;
  cursor:pointer;

}
#resQuotaRoot:hover .resourceQuotaHolder{
  border: 2px solid black;
}

#resQuotaRoot > svg {
  width: 20px;
  vertical-align: 0;
  margin-right: 5px;
}

.alertsView > #resQuotaRoot {
  margin-top: -26px;
  float: right;
}

#resQuotaRoot > span {
  vertical-align: -12px;

}

#resQuotaRoot > * {
  display: inline-block;
}

.largePaddingDialog {
  padding:20px;

}
.cg-tooMuch {
  color:var(--u-red);
}
.cg-wannaBeEaten {
  color:var(--u-primary-vibrant);
}

.MuiListItem-root.INACTIVE,
.MuiListItem-root.FREEZE,
.MuiListItem-root.STARTING{
  background: #777 !important;
}
.MuiListItem-root.ERROR {
  background: #84363c !important;
}

.rangeSelectorWrapper {
  width: 100%;
  position: relative;
  margin-top: 0.5em;
}

.rangeSelectorWrapper canvas {
  position:absolute;
  top:0;
  left:0;
}

.rangeSelectorFooter {
  width: 100%;
  display: flex;
  margin-top: 1em;
}

.rangeSelectorSlider {
  min-width: 10em;
  margin-left: 2em;
  margin-right: 2em;
  flex: auto;
  position: relative;
}

.rangeSelectorSlider>span.MuiSlider-root {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

#RangeSelectorCanvas {
  background-color: rgba(255, 255, 255, .2);

}

.grabCursor {
  cursor: grabbing;
}

.imageListWrapper {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.imageList {
  flex-wrap: nowrap;
  /* Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS. by MUI */
  transform: translateZ(0);
  width: 100%;
}

.imageListElemTitle {
  background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%);
}

.multilineBottom .MuiDialogActions-root {
  display: block;
}

.smallCircProgress {
  width:20px !important;
  height:20px !important;
  color:white;
}
#moreSolutionAvailable {
  margin:20px;
  max-width: 680px;
}
#functionalities {
  max-width: 680px;
  margin:15px 0px;
}
#functionalities span {
  vertical-align: -2px;
}
.typeIcon {
  margin:0 8px;
  background:var(--u-black);
  color:#fefefe;
  padding:0px 6px 0 10px;
  opacity: .95;

}
.typeIcon .duotone-img {
  width:25px;
  padding: 2px;
}

.alertTypeCardProvidedBy {
  position:absolute;
  right:10px;
  top:10px;
}
.alertTypeCardProvidedBy .duotone-img {
  width:25px;
  margin:0px 5px;
}

.consumption {
  position:absolute;
  right:10px;
  bottom:10px;
  color: #aaa;
  font-size: small;
}
.consumption:hover {
  color:#ddd;
}

.cameraForm > div > div {
  padding-bottom: 5px;
}
.loadingSwitch {
  width: 40px;
  text-align: right;
  padding: 0 9px 0 9px;
}
.alertsViewFlex button.MuiButtonBase-root.MuiCardActionArea-root {
  padding-bottom:80px;
}
#objectSizeAction {
  background:#fefefe;
  padding-top:20px;
  border-radius: 18px 18px 0 0;
  box-shadow:-3px 0px 5px #ccc;
}

.HourMinute {
  margin-top: 10px;
}
.debug-border {
  outline:2px solid red;
}
.card-img-holder {
  min-height:86px;
}
.propertyDescription i{
  color:#777 !important;
}
.propertyDescription {
  color:#222;
}
.formElementRoot {

}
