

. .spinner-border.text-primary {
        color:var(--primary) !important;
}
. .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background-color: var(--primary) !important;
}

. .btn-success {
        background: var(--u-black) !important;
        border-color: var(--u-black-dark) !important;
}

. .btn-success:active,
. .btn-success:focus {
        box-shadow: 0 0 0 0.2rem var(--u-black-shadow) !important;
}

. .btn-primary,
. .btn-outline-primary:hover,
. .btn-outline-primary:active,
. .btn-outline-primary:focus,
. .custom-control-input:checked ~ .custom-control-label::before {
        background: var(--u-primary) !important;
        border-color: var(--u-primary-dark) !important;
        color: white !important;
}

. .btn-outline-primary:active,
. .btn-outline-primary:focus,
. .btn-primary:active,
. .btn-primary:focus,
. .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 0.2rem var(--u-primary-shadow) !important;
}
/*
. input:not([disabled]):focus,
. input:not([disabled]):active,
. select:not([disabled]):focus,
. select:not([disabled]):active {
        box-shadow: 0 0 0 0.2rem var(--u-primary-shadow) !important;
        border-color: var(--u-primary-dark) !important;
}
*/
. .btn-outline-primary {
        color: var(--u-primary) !important;
        border-color: var(--u-primary-dark) !important;
}

. a:not(.active):hover {
        color: var(--u-primary-dark) !important;
}

.text-primary , #topTabContainer nav a {
        color: var(--u-primary) !important;
}
#topTabContainer nav a.active {
        color:#444 !important;
}
#topTabContainer .nav-tabs .nav-link.active {
        border-color: #999 #999 #fff !important;
}
#topTabContainer .nav-tabs {
        border-bottom:#999 !important;
}
/**
COLOR SCHEME - END
 */
.actually-alarmViewRoot > li > img {
  margin-right:10px;
  width:30px;
  height:30px;

}
 .actually-alarmViewRoot :not(.Mui-selected) .alarmTypeIcon {
  filter:invert();
}

.MuiDialog-paperFullScreen {
  background-color: var(--u-bg);
}

.MuiDialogContent-root:first-child {
  padding-top: inherit;
}
