@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,600;0,800;1,400&display=swap');

:root {
    --bright: #efca78;
    --vivid: orange;
    --ultinous: #ff5a21;
    --dark: #231F20;
}

body {
    background-color: whitesmoke;
}

.MuiButton-text.selected, .MuiListItemText-root.selected .MuiTypography-root {
    font-weight: bold;
}

.LoginPage {
    background: linear-gradient(180deg, #ddd, #f1f1f1);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;

}

@keyframes show-login {
    0% {
        opacity: 0.2;
        margin-top: 60px;
    }
    40% {
        margin-top: 50px;
    }
    100% {

        opacity: 1;
    }
}

.LoginPage::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    height: 6px;
    /*noinspection CssUnresolvedCustomProperty*/
    background: linear-gradient(-90deg, #f69c2399, var(--u-primary-selected));
}

.LoginCard {
    animation: show-login 0.9s;
    animation-fill-mode: forwards;
    text-align: center;
    margin: 50px auto;
    padding: 30px 20px 20px 20px;
    background-color: whitesmoke;
    max-width: 400px;
    border-radius: 8px;
    border: 1px solid #ccc;
}

.LoginCard-wide {
    max-width: 650px;
}

.largeForm {
    margin: 5px !important;
    width: calc(100% - 10px);
}

.formSubmit {
    margin-left: 5px !important;
}

.progressBar {
    width: calc(100vw - 100px);
    margin-top: 10px;
}

.progressBar.small {
    width: 700px;
    margin-top: 10px;
}

.progressBar.wrapped {
    width: 100%;
    margin-top: 10px;
}


.fullWidth {
    width: 100%;
}

.formHolder {
    margin-left: -5px;
}

.pointer {
    cursor: pointer;
}

.hoverColor:hover {
    background-color: #ddd;
}

.selectedLine {
    background-color: var(--vivid);
}

.bold {
    font-weight: bold;
}

.smallCell {
    width: 1px;
    white-space: nowrap;
}

.MuiOutlinedInput-root.off {
    color: #ccc;
}

.action,
button.cta {
    border-radius: 8px !important;
    box-shadow: none !important;
}

.action.right {
    border-radius: 0 15px 15px 0 !important;
}

.action.left {
    border-radius: 15px 0 0 15px !important;
}

.action.center {
    border-radius: 0 !important;
}

.MuiButtonGroup-contained {
    box-shadow: none !important;
}

.MuiButtonGroup-contained button {
    background-color: var(--bright) !important;
    border-color: var(--dark) !important;
    color: var(--dark) !important;
}

.MuiButtonGroup-outlined button.cta {
    border-color: var(--dark) !important;
}

button.contained, .mtrBox button.cta {
    background-color: var(--bright) !important;
    border-color: var(--bright) !important;
    color: var(--dark) !important;
}

.mtrBox button.cta:disabled {
    background: #ccc !important;
}

button.MuiButton-root.noMinWidth {
    min-width: unset !important;
}

.MuiButton-outlinedPrimary.action, .MuiButton-outlinedPrimary.action:hover {
    border-color: var(--dark);
    color: var(--dark);
    margin-right: 3px;
}

.MuiButtonGroup-root .MuiButton-outlinedPrimary.action {
    margin-right: 0 !important;
}

.MuiButtonGroup-root .MuiButton-containedPrimary.action {
    border-color: var(--vivid);
    border-width: 1px !important;
}

button.action:hover, .MuiButton-containedPrimary.action.delete:hover {
    background-color: var(--vivid) !important;
    box-shadow: none;
    color: var(--dark);
    border-color: var(--vivid);
}

.MuiButton-containedPrimary.action {
    background-color: var(--bright);
    box-shadow: none;
    color: var(--dark);
}

.MuiButton-containedPrimary.action.delete {
    background-color: var(--dark);
    color: white;
}

.MuiButton-containedPrimary.action.delete:disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
}

.MuiAppBar-colorPrimary {
    background: var(--dark) !important;
}

.copyButton {
    color: var(--dark) !important;
}

#logo {
    margin-top: 3px;
    margin-right: 3px;
}

#loginLogo {
    height: 64px;
    width: 64px;
}

.MuiTableCell-root > button {
    margin-top: 1px;
    margin-bottom: 1px;
}

.readonly {
    background-color: #ddd;
}

.readonly input {
    color: #444;
    cursor: default;
}

.right {
    text-align: right;
    display: inline-block;
    float: right;
}

.configHelp {
    margin-top: 10px;
}

.configHelp .MuiTypography-root {
    line-height: 2;
}

.configHelp .MuiIconButton-root svg {
    height: 0.8em;
}

.tinyImg {
    max-width: 250px;
    max-height: 250px;
}

.marginTop1 {
    margin-top: 1em !important;
    margin-bottom: 0.5em !important;
}

.middleImg {
    width: 25vw;
    margin-top: 1em;
}

.withPadding {
    padding: 1em;
}

.largeImg {
    width: 100%;
    max-height: 100%;
}

.middleImg {
    max-height: 80%;
    max-width: 80%;
}

.action.login {
    margin: 1em;
}

.paddingH24 {
    padding-left: 24px;
    padding-right: 24px;
}

.paddingL1 {
    margin-left: 1em !important;
}

.positiveCount {
    color: var(--dark);
}

.allCount {
    color: #888;
}

.selected.imageListItem {
}

.notSelected.imageListItem {
    filter: grayscale(60%) brightness(50%);
}

.MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
}

.MuiAccordion-root, .MuiAccordionSummary-root, .MuiAccordionSummary-root {
    width: 100% !important;
}

.MuiPaper-root.MuiPaper-elevation.Mui-expanded {
    margin-top: 0;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin-bottom: 0 !important;
}

.infoIcon svg {
    vertical-align: middle;
    font-size: medium;
}

.MuiDialogActions-spacing {
    margin-bottom: 16px;
    margin-right: 16px;
}

.almostFullScreenDialog {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999;
    margin: 0;
    background-color: var(--dark);
}

.almostFullScreenDialog {
    /*margin: 1em;*/
    /*background-color: #ccc;*/
}

.almostFullScreenDialog > div {
    margin: 1em;
    padding: 1em;
    background-color: white;
}

.hidden {
    display: none;
}

.rangeSelectorWrapper {
    width: 100%;
    position: relative;
    margin-top: 0.5em;
}

.rangeSelectorWrapper canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.rangeSelectorFooter {
    width: 100%;
    display: flex;
    margin-top: 1em;
}

.rangeSelectorSlider {
    min-width: 10em;
    margin-left: 2em;
    margin-right: 2em;
    flex: auto;
    position: relative;
}

.rangeSelectorSlider > span.MuiSlider-root {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
}

#RangeSelectorCanvas {
    background-color: rgba(255, 255, 255, .2);

}

@media (min-width: 0px) and (orientation: landscape) {
    #logo {
        height: 48px;
        width: 48px;
    }
}

@media (min-width: 600px) {
    #logo {
        height: 50px;
        width: 50px;
    }

    .MuiAccordion-root {
        width: fit-content;
        max-width: 100%;
    }

}

.copyButton {
    transition: all .4s;
}

.copyButton.active-cpy {
    transition: all .4s;
    border-color: #fd7e14 !important;
    background: #fd7e14 !important;
}

#adminPanel {
    max-width: 800px;
    margin: 20px auto;
}

#adminDrawer > div {
    position: fixed;
    /*left: 20px;*/
    top: 85px;
    height: 300px;
    width: 200px;
    /*border-radius: 10px;*/
    /*border: 2px solid #666;*/
    width: 250px;
    top: 65px;
    height: 100%;
}

/*#adminDrawer {*/

/*    overflow: hidden;*/

/*}*/

/*#adminDrawer .MuiButtonBase-root {*/
/*    border-bottom: 2px solid #666;*/
/*}*/

#adminDrawer a {
    width: 100%;
    text-decoration: none;
    color: black;
}

#AdminRoot .MuiToolbar-root {

}

#AdminOutlet {
    margin-left: 290px;
    margin-top: 50px;
    position: relative;
}

#Accounts-DataGrid {
    max-width: 750px;
}

.url-element {
    transition: .2s;
    border-right: 0px solid var(--admin-accent);
}

#adminDrawer .MuiButtonBase-root div > span,
.url-element div > span, .active-url {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 400;
}


.active-url {
    border-right: 4px solid var(--admin-accent);
    background: #f1f1f1;
}

.active-url div > span {
    color: var(--admin-accent) !important;
    font-weight: 600;
}

.url-element svg {
    transition: .2s;
}

.active-url svg {
    transition: .8s;
    fill: var(--admin-accent);
}

#Accounts-AddFab {
    position: absolute;
    margin-top: -18px;
    margin-left: calc(750px - 240px);
    z-index: 1;
}

.rightDrawer {
    z-index: 99;
    position: fixed;
    top: 50px;

    padding: 30px;
    right: 0px;
    width: min(max(calc(100% - 750px - 395px), 300px), 500px);
    height: 100%;
}

.flr {
    float: right;
}

.rightDrawer .mw800 .MuiDivider-root {
    margin: 5px -15px;
}

.textInput {

    margin-bottom: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.sizeloadedBtns {
    margin: 0 -5px;
}

.sizeloadedBtns button {
    width: calc(50% - 10px);
    margin: 5px;
}

.sizeloadedBtns.thirds button {
    width: calc(33% - 10px);
    margin: 5px;
}

.sizeloadedBtns button.wide {
    width: calc(100% - 10px);
    margin: 5px;
}

.noa {
    text-decoration: none;
}

@media (max-width: 1450px) {
    .rightDrawer {
        box-shadow: 1px 0px 6px #888 !important;
    }

    .sizeloadedBtns.thirds-reflow-two button.MuiButton-contained {
        width: calc(100% - 10px);
    }

    .sizeloadedBtns.thirds-reflow-two button {
        width: calc(50% - 10px);
    }
}

@media (min-width: 1650px) {
    #Accounts-DataGrid {
        max-width: calc(100% - 500px - 105px);
    }

    #Accounts-AddFab {
        margin-left: calc(100% - 500px - 105px - 240px);
    }
}

.rightDrawer .MuiDivider-root {
    margin: 5px -30px;
}

.denseGroup .MuiRadio-root {

    padding: 5px 9px;

}

.explainer {
    text-align: justify;
}

.userLink {
    color: #0a58ca;
}

.seperatedList label {
    display: block;
    margin: 0 -30px 0 -30px;
    padding: 0 20px;
    border-left: 3px solid transparent;
    transition: background .2s, border-left-color .1s;
}


.seperatedList label:has(.Mui-checked) {
    border-left-color: var(--admin-accent);
    background: #673AB710;
}

#productName {
    font-family: 'Montserrat', sans-serif;
    margin-left: 15px;
    font-weight: 600;
}

#drawer-admin #productName {

}

.MuiAppBar-colorPrimary {
    background: linear-gradient(90deg, #444, #444) !important;
}

#drawer-admin.MuiAppBar-colorPrimary {
    transition: .2s;
    background: linear-gradient(90deg, #444, var(--admin-accent)) !important;
}

#AdminRoot .MuiFab-primary,
#AdminRoot .MuiButton-containedPrimary {
    background: var(--admin-accent) !important;
    color: white !important;
}

#AdminRoot .MuiFab-primary.Mui-disabled,
#AdminRoot .MuiButton-containedPrimary.Mui-disabled {
    background: rgba(0, 0, 0, 0.12) !important;
}

#AdminRoot .MuiButton-outlinedPrimary {
    border-color: var(--admin-accent);
    color: var(--admin-accent);
}

#AdminRoot .MuiCheckbox-root.Mui-checked svg {
    fill: var(--admin-accent);
}

#AdminRoot h3, #UserspaceRoot h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    letter-spacing: -2px;
    color: #444;
    background: -webkit-linear-gradient(0deg, #444, #4527a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline;
    width: fit-content;
    padding-right: 6px;
}

#UserspaceRoot h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    letter-spacing: -2px;
    color: #444;

    font-size: 42px;
    display: inline-block;
    background: -webkit-linear-gradient(0deg, #444, #986032);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 15px;
    padding-right: 5px;
}

:root {
    --admin-accent: #673ab7;
}

#noRouter {
    width: 450px;
    margin-left: -15px;
}

#noRouter.full, .fullAlert {
    margin: 20px auto 0px auto;
}

.MuiDialog-container {
    margin-top: -100px;
}

.ml-10 {
    margin-left: 10px;
}

.LoginCard button.MuiButton-containedPrimary {
    min-width: 250px;
}

.ta-left {
    text-align: left;
}

.rightDrawer {
    overflow-x: auto;
    max-height: calc(100% - 40px);
}

.rightDrawer-sticky {
    position: sticky;
    padding-top: 10px;
    top: -20px;
    background: white !important;
    z-index: 2;
}


.mtrBox {
    margin: -14px 15px 20px 15px;
    min-width: 800px;
    max-width: 1000px;
}

/**

 */
.MuiSwitch-switchBase.Mui-checked {
    color: #fd7e14 !important;
}

.Mui-checked + .MuiSwitch-track {
    background: #fd7e14 !important;
}

#siteWrapper {
    max-width: 1000px;
}

.mw-960 {
    max-width: 960px;
}

.rowItem {
    vertical-align: 6px;
    display: inline-block;
}

.ufilter-validationerror {
    margin: -40px 5px 25px 5px;
}

.ufilter-validationerror p {
    margin: 0px;
}

.MuiCircularProgress-circleIndeterminate {
    color: #fd7e14;
}

.p {
    border-left: 4px solid #ccc;
    margin: 17px 3px 17px 0px;
    padding-left: 10px;
    color: #666;
    font-size: 16px;
}

.shortAlert {
    width: 450px;
}

.shortTable {
    max-width: 500px;
}

.LoginCard button.MuiButton-containedPrimary {
    min-width: 250px;
}

.ta-left {
    text-align: left;
}

.subtle-aContainer a {
    color: #444;
}

.subtle-aContainer a:visited {
    color: #444;
}

.subtle-aContainer a:hover {
    color: #666;
}

.subtle-aContainer a:active {
    color: black;
}

.siteListItem {
    vertical-align: -1px;
    display: inline-block;
    margin-left: 10px;
    font-size: 16px;
}

.rootWrapper {
    margin: 20px 40px 10px 40px;
    max-width: 1000px;
}

.noTopPad {
    margin-top: 5px;
}

#AppToolbar {
    position: absolute;
    top: 65px;
    background: white;
    left: 0;
    right: 0;
    height: 32px;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

#AppToolbar-Clearfix {
    height: 110px;
}

.toolbarBtn {
    margin-top: -4px !important;
    display: inline-block;

    font-size: 17px !important;
    color: #444 !important;
}

.smallArrow {
    width: 24px !important;
}

#iconsLeft {
    text-align: right;
    display: inline-block;
    float: right;
}

#parsedPath {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    vertical-align: 2px;
    margin-left: 60px;
}

.parsedPathItem {
    color: #444;
    max-width: 80px;
    overflow: hidden;
    display: inline-block;
    height: 20px;
    vertical-align: -4px;
    text-overflow: ellipsis;
}

.parsedPathItem:hover {
    color: #222;
    text-decoration: underline;
    cursor: pointer;
}

.parsedPathRoute {
    font-weight: 600;
}

.normalPaper {
    width: 600px;
    padding: 20px;
}

.wrappedLink {
    display: inline-block;
    margin-right: 20px;
}

.wrappedLink .mui-Paper {
    display: inline-block;
}

.wrappedLink a {
    text-decoration: none;
}

.wrappedLink button {
    color: #444 !important;
    font-size: 14px;
    padding: 6px 10px 6px 10px;
}

.wrappedLink svg {
    fill: #fd7e14 !important;
    font-size: 15px;
    margin-left: 3px;
}

.wrappedLink-admin svg {
    fill: var(--admin-accent) !important;
}

.wrappedLink-dark button {
    background: #ccc;
    color: #222 !important;
}

.wrappedLink-admin.wrappedLink-dark button {
    background: var(--admin-accent);
    color: #fefefe !important;
}

.wrappedLink-admin.wrappedLink-dark svg {
    fill: #f1f1f1 !important;
}

.cDialog [role="dialog"] {
    min-width: 400px;
    white-space: pre-line;
}

.alignRight {
    text-align: right !important;
}

.fullDialog .MuiDialog-container {
    margin-top: 0 !important;
}

body.scuiShown iframe#scuiIframe {
    display: block;
    position: fixed;
    top: 0px;
    right: 0;
    /* bottom: 44px; */
    left: 0;
    height: calc(100% - 0px);
    width: 100%;
    z-index: 1000;
    border: none;
    opacity: 1;
}

body.scuiShown #root > nav {
    display: none;
}

.cover {
    border-radius: 8px;
    border: 1px solid #bbb;
    padding: 15px;
    margin: 5px;
    /*max-width: 450px;*/
    background: white;
}

.cover.standalone {
    margin: 0;
    padding: 20px;
}

.coverHeader {
    padding: 15px 20px;
    margin: -19px -20px -15px -20px;
    position: sticky;
    top: 65px;
    background: white;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #bbb;
    z-index: 9;
}

.cover.table.standalone {
    padding: 0px;
    overflow: hidden;

}

.cover.table table {
    border-radius: 4px;

    padding-left: 5px;
}

.cover.table tr {
    border-left: 3px solid transparent;

}

.mw800 {
    max-width: 860px;
    display: block;
}

.cover.table tr:not(:has(th)):hover {
    border-left: 3px solid var(--vivid);
    background: #eee;
}

.standalone .coverItem {
    padding: 5px 0px 7px 20px;
    margin-left: -20px;
    border-left: 3px solid #ff7b4d;

}

.standalone .coverItem h4 {
    padding-top: 3px;
    color: #582919;
}

.pageContainer {
    padding-right: 50px;
}

.cover h4, .cover h5 {
    font-size: 20px;
    margin: 5px 0 25px 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.minimalTable td {
    padding: 3px;
}

.alignGridItem {
    margin: 6px;
}

.shortInput {
    width: 400px;
}

.cover.shortInput {

}

h4.dividerItem, h5.dividerItem {
    font-weight: 400;
    position: relative;
    padding: 20px 0 0 10px;
}

h4.dividerItem::before, h5.dividerItem::before {
    content: '';
    height: 1px;
    background: #ccc;
    position: absolute;
    left: -15px;
    right: -20px;
    top: 0px;
}

h5.subtitleItem {
    font-weight: 400;
    margin: 20px 0 10px 5px;
    font-size: 16px;
}

button svg {
    width: 20px;
    margin-right: 5px;
}

button.icon svg {
    margin: 0;
}

@media (max-width: 600px) {
    #AppToolbar {
        top: 56px;
    }

    #AppToolbar-Clearfix {
        top: 80px;
    }

    .rootWrapper {
        margin: 20px 25px 10px 25px;
        max-width: 1000px;
    }

    .hideSmall {
        display: none;
    }
}

.autogen {
    font-size: 14px;
    color: #444444;
}

.w100 {
    width: 100%;
}

.line-clamp, h5, h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.line-clamp-2 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.line-clamp-3 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.limitWidth {
    max-width: 300px;
    overflow: hidden;
    line-break: anywhere;
}

.vam {
    margin: 10px -5px 0 5px !important;
}

.filterMotionless {
    margin-left: 15px;
    margin-top: -5px;
    margin-bottom: 10px;
    color: #222;

}

.senform {
    margin-left: 13px !important;

}

.smallcell {
    width: 150px;
}

.smtpNotice {
    display: block;
    margin-top: -35px;
    margin-left: 290px;
}
