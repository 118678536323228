.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dualListBox {
  margin: 20px 0;
  padding: 10px;

  border: 1px solid #ccc;
  background: #f8f9fa;
  border-radius: 3px;
  max-width: max(80%, 960px);
}

.dualListBox-header {
  background: #fff;
  padding: 5px 10px;
  margin: -9px -9px 10px;
  border-bottom: 1px solid #ccc;
}

.dualListBox select {
  height: 130px;

}

.dualListBox-header span {
  text-align: right;
  float: right;
}


.dualListBox-limitReached .rdl-actions-right button,
.dualListBox-none .rdl-actions-left button {
  cursor: not-allowed;
  outline: none;
  background: #ccc !important;
  border: 1px solid #ccc !important;
}

.dualListBox-textboxLimitReached {
  font-weight: bold;
  color: red;
}

.dualListBox:not(.really-empty) .react-dual-listbox .rdl-selected select:empty::before {
  content: 'No solutions are selected.\a Add one from the left by double clicking on it. \a Drawing order can be changed with the buttons on the right.';
  display: block;
  white-space: pre-wrap;
  text-align: center;
  margin-top: 15px;
  color: #666;
}

.dualListBox.really-empty .react-dual-listbox .rdl-available select:empty::before {
  content: 'No solutions are available for this camera.\a There will be no annotations on the RTSP output.';
  display: block;
  white-space: pre-wrap;
  text-align: center;
  margin-top: 15px;
  color: #666;
}

.rdl-control {
  outline: none;
}

.rdl-control option:active {
  color: white;
  background: var(--primary);
}

.dualListBox .rdl-control {
  width: 100%;
}

select.rdl-control {
  width: 200px;
}
