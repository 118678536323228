.tab-container {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}


.tab-container .nav-link {
  padding-left: 2.5rem !important;
  border: 1px solid #ccc;
  margin: 5px;
  display: flow-root;
}

.tab-container .nav-link.active {
  border-color: var(--primary);
}



.nav-item-category {
  margin: 15px;
  font-weight: bold;
}
.task-tab-empty-state {
  text-align: center;
  font-size: 17px;
  padding: 25px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #efefef;

  user-select: none;
}
.task-tab-empty-state svg {
  display: block;
  text-align: center;
  margin: 20px auto;
  font-size: 55px;
  color: #999;
}
.tab-empty-state-container {
  margin: 5px 35px;
}
.tab-empty-state-container .tab-empty-state {
  margin:0px;
}
.contentRootTab {
  position: relative;

}
